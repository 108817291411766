import Rest from '@/services/Rest';

/**
 * @typedef {EmailVerificationService}
 */
export default class EmailVerificationService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/email-verification';

}
