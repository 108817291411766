<template>
  <b-container id="content-verification" fluid class="bg-login full-height">
    <b-row class="full-height">
      <b-col cols="12" md="6" class="left-side d-none d-md-block" :class="{'heaven': isHeaven}"> </b-col>
      <b-col cols="12" md="6" class="right-side px-3 d-flex justify-content-center align-item-center">
        <div class="inner-wrapper" data-anima="right" v-if="!loading">
          <!-- Logo -->
          <span class="logo mb-5">
            <img v-if="!isHeaven" src="@/assets/logo.png" />
            <img v-else src="@/assets/img/icons-heaven/logo.svg" />
          </span>

          <span>
            <div data-anima="top">
              <p class="text">
                {{ $t('views.seller.verification.text1') }}
              </p>
            </div>

            <b-row class="mt-4">
              <b-overlay
                :show="loading"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="btn-acoes"
              >
                <BaseButton variant="black" @click="onSubmit()"
                  >{{ $t('views.seller.verification.send_new_verification_link') }}</BaseButton
                >

                <a href="#" @click.prevent="logout" class="logout"
                  >{{ $t('views.seller.verification.access_another_account') }}</a
                >
              </b-overlay>
            </b-row>

            <b-row class="mt-5">
              <a
                class="links-help"
                :href="isHeaven
                  ? (
                    $i18n.locale === 'en'
                      ? 'https://heaven.me/heaven-privacy-policy/'
                      : $i18n.locale === 'es'
                        ? 'https://heaven.me/heaven-politica-de-privacidad/'
                        : 'https://heaven.me/heaven-politica-de-privacidade/'
                    )
                  : 'https://greenn.com.br/politica-de-privacidade-greenn/'
                "
                target="_blank"
              >
                {{ $t('views.seller.verification.privacy_policy') }}
              </a>
            </b-row>
          </span>
        </div>

        <div
          v-if="loading"
          class="py-4 d-flex justify-content-center align-items-center"
        >
          <b-spinner variant="dark" label="Spinning"></b-spinner>
          <p class="recupera-senha-text ml-4">{{ $t('views.seller.verification.processing') }}</p>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Cookies from 'js-cookie'
import crisp from '@/mixins/crisp.js'

import EmailVerificationService from '@/services/resources/EmailVerificationService'
import MetaService from '@/services/resources/MetaService'

const serviceEmail = EmailVerificationService.build()
const serviceMeta = MetaService.build()

export default {
  name: "Verificação",
  data() {
    return {
      email: "",
      success: true,
      loading: false,
      hash: null,
      interval: null,
      user: {},
      userLogged: {}
    }
  },
  mixins: [crisp],
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    getUser() {
      this.$store.dispatch("userRequest").then((response) => {
        this.user = response
        this.email = this.user.email
      })
    },
    logout() {
      this.$store.dispatch("logoutRequest").catch((err) => {});
    },
    onSubmit() {
      this.loading = true;
      let data = {
        email: this.$store.getters.getProfile.email,
      };
      serviceEmail
        .create(data)
        .then((response) => {
          if (response.success) {
            this.$bvToast.toast(this.$t('views.seller.verification.new_verification_link_sent'), {
              title: this.$t('views.seller.verification.verification'),
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async verificaDados() {
      this.userLogged = await this.$store.dispatch('userRequest')
      this.$store
        .dispatch("emailVerification", this.userLogged.type)
        .then((resp) => {
          if (resp) {
            this.$router.push(`/dashboard${window.location.search}`);
          } else {
            this.loading = false;
          }
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
        });
    },
    getHash() {
      this.loading = true;
      let data = {
        id: this.$route.params.hash,
      };
      serviceEmail
        .update(data)
        .then((resp) => {
          if (this.$store.getters.isAuthenticated) {
            if (resp.success) {
              this.$store.state.user = resp.user;
              Cookies.set("user", JSON.stringify(resp.user), {
                expires: 365,
              });
              localStorage.setItem("user", JSON.stringify(resp.user))
              clearInterval(this.interval);
              window.location.reload();
            } else {
              if (this.$store.getters.verifyEmail) {
                this.$router
                  .push(`/dashboard${window.location.search}`)
                  .catch();
                return;
              } else {
                this.verificaDados();
              }
            }
          } else {
            if (resp.success) {
              clearInterval(this.interval);
              let access_token = "Bearer " + resp.access_token;
              Cookies.set("access_token", access_token, {
                expires: 365,
              });
              this.$store.state.auth.access_token = access_token;
              this.$store.state.user = resp.user;
              Cookies.set("user", JSON.stringify(resp.user), {
                expires: 365,
              });
              localStorage.setItem("user", JSON.stringify(resp.user))
              window.location.reload();
            }
          }
        })
        .catch((err) => {
          console.error(err);
          this.verificaDados();
        });
    },
    initVerify() {
      if (this.$route.params.hash) {
        this.getHash();
      } else if (this.$store.getters.verifyEmail) {
        this.$router
          .push(`/dashboard${window.location.search}`)
          .catch();
        return;
      } else {
        this.verificaDados();
      }
    },
    pollData() {
      this.interval = setInterval(() => {
        this.initVerify();
      }, 10000);
    },
    addMeta() {
      let data = {
        meta_key: "onboarding",
        meta_value: 0,
      };
      serviceMeta.create(data);
    },
    fetchMetas() {
      if (this.$store.state.isAuthenticated) {
        let data = {
          id: "onboarding",
        };
        serviceMeta.read(data).then((response) => {
          if (typeof response.meta_value == "undefined") {
            this.addMeta();
          }
        });
      }
    },
    installTags() {
      // tags google
      let googleScript = document.createElement("script");
      googleScript.setAttribute(
        "src",
        "https://www.googletagmanager.com/gtag/js?id=AW-11221195320"
      );
      googleScript.async = true;
      document.head.appendChild(googleScript);

      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", "AW-11221195320");
      gtag("event", "conversion", {
        send_to: "AW-11221195320/mkFhCLKXrdoCENjVm78C",
      });

      const script = document.createElement('script');
      script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-TRVTZVCJ');
      `;
      document.head.appendChild(script);

      const noscript = document.createElement('noscript');
      noscript.innerHTML = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TRVTZVCJ"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
      document.body.prepend(noscript);
      // fim tags google

      // inicio pixel tiktok

      !(function (w, d, t) {
        w.TiktokAnalyticsObject = t;
        var ttq = (w[t] = w[t] || []);
        (ttq.methods = [
          "page",
          "track",
          "identify",
          "instances",
          "debug",
          "on",
          "off",
          "once",
          "ready",
          "alias",
          "group",
          "enableCookie",
          "disableCookie",
        ]),
          (ttq.setAndDefer = function (t, e) {
            t[e] = function () {
              t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
            };
          });
        for (var i = 0; i < ttq.methods.length; i++)
          ttq.setAndDefer(ttq, ttq.methods[i]);
        (ttq.instance = function (t) {
          for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
            ttq.setAndDefer(e, ttq.methods[n]);
          return e;
        }),
          (ttq.load = function (e, n) {
            var i = "https://analytics.tiktok.com/i18n/pixel/events.js";
            (ttq._i = ttq._i || {}),
              (ttq._i[e] = []),
              (ttq._i[e]._u = i),
              (ttq._t = ttq._t || {}),
              (ttq._t[e] = +new Date()),
              (ttq._o = ttq._o || {}),
              (ttq._o[e] = n || {});
            var o = document.createElement("script");
            (o.type = "text/javascript"),
              (o.async = !0),
              (o.src = i + "?sdkid=" + e + "&lib=" + t);
            var a = document.getElementsByTagName("script")[0];
            a.parentNode.insertBefore(o, a);
          });

        ttq.load("C5O60ULO3VNUQLVLNK00");
        ttq.page();
      })(window, document, "ttq");

      // fim pixel tiktok
    },
  },
  mounted() {
    this.loading = true
    this.installTags()
    this.fetchMetas()
    this.initVerify()
    this.pollData()
    this.getUser()

    // add crisp no greenn

    let notify = document.getElementById("1WZET722PJibpmL_crisp");
    if (notify === null) {
      window.$crisp = [];

      this.crispWitUser()
      this.appendScriptCrisp()

    } else {
      const crisp = document.querySelector(".crisp-client");
      crisp.style.display = "block";
      this.crispWitUser(false)

    }
    // fim crisp
  },
  async created() {
    this.userLogged = await this.$store.dispatch('userRequest')
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.text-green {
  color: $base-color;
  font-weight: bold;
}
.text-green.heaven {
  color: $base-color-heaven;
  font-weight: bold;
}

.bg-login {
  background: $base-color;
}

.form-group {
  position: relative;
}
.form-group + .form-group {
  margin-top: 20px;
}

.left-side {
  background-image: url("~@/assets/img/cadastro-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.left-side.heaven {
  background: radial-gradient(circle, $base-color-heaven-light 32%, $base-color-heaven 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  right: 0;
  top: 0;
  z-index: 88888;
  width: 100%;
  height: 100vh;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.left-side.heaven::after {
  content: url("~@/assets/img/icons-heaven/logo.svg");
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.right-side {
  background: #fff;
  padding: 20px;
  height: 100%;

  .inner-wrapper {
    margin-top: 90px;
    width: 60%;

    .logo {
      img {
        width: 120px;
        height: auto;
        margin-bottom: 40px;
      }
    }
  }
}
.recupera-senha-text {
  color: #81858e;
  font-size: 14px;
}
.links-help {
  font-size: 12px;
  color: #81858e;
  margin-top: 20px;
}
.mostrar-senha {
  font-size: 13px;
  position: absolute;
  top: 55%;
  right: 20px;
  cursor: pointer;
  color: #81858e;
}
@media screen and (max-width: 768px) {
  .inner-wrapper {
    width: 85% !important;
  }
  .logo img {
    width: 80px !important;
    margin-bottom: 30px !important;
  }
  .links-help {
    margin: 0;
  }
}

.text,
.logout {
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
  margin-bottom: 20px;
}

.logout {
  display: block;
  color: #333;
  margin-top: 20px;
}
</style>

<style>
.main {
  padding: 0px !important;
}
</style>
