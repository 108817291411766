var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"bg-login full-height",attrs:{"id":"content-verification","fluid":""}},[_c('b-row',{staticClass:"full-height"},[_c('b-col',{staticClass:"left-side d-none d-md-block",class:{'heaven': _vm.isHeaven},attrs:{"cols":"12","md":"6"}}),_c('b-col',{staticClass:"right-side px-3 d-flex justify-content-center align-item-center",attrs:{"cols":"12","md":"6"}},[(!_vm.loading)?_c('div',{staticClass:"inner-wrapper",attrs:{"data-anima":"right"}},[_c('span',{staticClass:"logo mb-5"},[(!_vm.isHeaven)?_c('img',{attrs:{"src":require("@/assets/logo.png")}}):_c('img',{attrs:{"src":require("@/assets/img/icons-heaven/logo.svg")}})]),_c('span',[_c('div',{attrs:{"data-anima":"top"}},[_c('p',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t('views.seller.verification.text1'))+" ")])]),_c('b-row',{staticClass:"mt-4"},[_c('b-overlay',{staticClass:"btn-acoes",attrs:{"show":_vm.loading,"rounded":"","opacity":"0.6","spinner-small":"","spinner-variant":"primary"}},[_c('BaseButton',{attrs:{"variant":"black"},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v(_vm._s(_vm.$t('views.seller.verification.send_new_verification_link')))]),_c('a',{staticClass:"logout",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('views.seller.verification.access_another_account')))])],1)],1),_c('b-row',{staticClass:"mt-5"},[_c('a',{staticClass:"links-help",attrs:{"href":_vm.isHeaven
                ? (
                  _vm.$i18n.locale === 'en'
                    ? 'https://heaven.me/heaven-privacy-policy/'
                    : _vm.$i18n.locale === 'es'
                      ? 'https://heaven.me/heaven-politica-de-privacidad/'
                      : 'https://heaven.me/heaven-politica-de-privacidade/'
                  )
                : 'https://greenn.com.br/politica-de-privacidade-greenn/',"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('views.seller.verification.privacy_policy'))+" ")])])],1)]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"py-4 d-flex justify-content-center align-items-center"},[_c('b-spinner',{attrs:{"variant":"dark","label":"Spinning"}}),_c('p',{staticClass:"recupera-senha-text ml-4"},[_vm._v(_vm._s(_vm.$t('views.seller.verification.processing')))])],1):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }